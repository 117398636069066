import { createBrowserRouter } from 'react-router-dom';
import Loadable from '../components/elements/Loadable/Loadable';
import { lazy } from 'react';

const Cart = Loadable(lazy(() => import('../pages/Cart')));
const Item = Loadable(lazy(() => import('../pages/Item')));
const Menu = Loadable(lazy(() => import('../pages/Menu')));
const MenuLayout = Loadable(lazy(() => import('../components/layouts/MenuLayout')));
const Portal = Loadable(lazy(() => import('../pages/Portal')));
const Splash = Loadable(lazy(() => import('../pages/Splash')));
const Tenant = Loadable(lazy(() => import('../pages/Tenant')));

const router = createBrowserRouter([
  { path: '/', element: <Splash /> },
  {
    path: '/cart',
    element: <MenuLayout />,
    children: [{ path: '/cart', element: <Cart /> }],
  },
  { path: '/portal', element: <Portal /> },
  {
    path: '/menu',
    element: <MenuLayout />,
    children: [
      { path: '/menu', element: <Menu /> },
      { path: '/menu/tenant/:id', element: <Tenant /> },
      { path: '/menu/tenant/:id/item/:itemId', element: <Item /> },
    ],
  },
]);

export default router;
